@use '../../../styles/_portal-variables.scss' as tresplay;
@use '../../../styles/_global-variables.scss' as global;

.menu {
    position: sticky;
    z-index: 9;
    top: 0;
    height: 90px;
    margin-bottom: calc(var(--gapRow) - 18px);
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
        z-index: -1;
        opacity: 0.8;
        transition: opacity 1s;
    }
    &.dark {
        &:before {
            opacity: 1;
        }
    }

    button {
        cursor: pointer;
    }
}

.menuWebView {
    position: sticky;
    z-index: 9;
    top: 0;
    .menuItems {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        height: 42px;
        font-size: var(--body_m);
        width: auto;
        z-index: 9;
        left: 12px;
        & > ul > li > a:hover,
        & > ul > li > button:hover,
        & > ul > li > .active {
            border-bottom: 1px solid #fff;
            margin-bottom: -1px;
        }
    }
}

.submenu {
    max-width: 2144px;
    margin: 0 auto 128px;
    width: 100%;
    z-index: 9;
    margin-left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    position: sticky;
    margin-top: -144px;
    .menuItems {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        height: 72px;
        font-size: var(--body_m);
        width: auto;
        overflow: visible;
        position: fixed;
        top: 0;
        z-index: 9;
        left: 277px;
        right: 200px;
        & > ul > li > a:hover,
        & > ul > li > button:hover,
        & > ul > li > .active {
            border-bottom: 1px solid var(--clr_portal_100);
            margin-bottom: -1px;
        }
    }
    :global {
        .swiper {
            background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.4) 63.33%, rgba(0, 0, 0, 0.0001) 100%);
            background-size: 100% 74px;
            background-repeat: no-repeat;
        }
    }
}
.menuItem {
    margin-right: 16px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    width: auto !important;
    font-weight: 500;
    .mainMenu & {
        font-weight: 900;
    }
    &.selected {
        .botoDropdown {
            border-bottom: 1px solid var(--clr_portal_100);
        }
    }
}
.menuItemSeccio {
    width: 100%;
    border-bottom: 0.5px solid global.$color80;
    padding: 10px 16px 10px 16px;
    font-size: var(--body_l);
}
.seccionsWebview {
    padding-bottom: 16px;
}
.seccionsWebview:first-child {
    border-top: 0.5px solid global.$color80;
}
.menuItemSeccio .linkSeccio {
    display: flex;
    align-items: center;
}
.itemSeccio {
    width: 100%;
}
.menuTitle {
    font-weight: 900;
}
.programa .menuItems {
    left: 400px;
}
.titolMenu {
    font-size: inherit;
    font-weight: inherit;
}
.menuWrapper {
    height: 72px;
    padding: 0 56px;
    display: flex;
    align-items: center;
    max-width: 2144px;
    margin: 0 auto;
}
.logoBarra {
    filter: brightness(0) invert(1);
    &:hover {
        filter: none;
    }
}
.logo {
    margin-right: 58px;
    width: 111px;
    height: 32px;
    line-height: 0;
    margin-top: -5px;
}
.iconaMenu {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.open {
    .dropdown {
        max-height: 650px;
        opacity: 1;
        pointer-events: all;
    }
    .iconaDropdown {
        transform: rotate(180deg);
    }
}
.botoDropdown {
    height: 20px;
    margin-bottom: -1px;
    .iconaDropdown {
        vertical-align: top;
    }
}
.iconaDropdown {
    transition: all 0.2s;
    width: 28px;
    height: 28px;
    margin-left: 0px;
    margin-top: -3px;
    margin-right: -8px;
}
.dropdown {
    position: absolute;
    top: 62px;
    left: -105px;
    background: rgba(0, 0, 0, 0.95);
    box-shadow: 0px 20px 28px rgba(0, 0, 0, 0.4);
    min-width: 280px;
    transition: all 0.2s;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    font-size: var(--body_l);
    font-weight: 400;
    padding: 15px 30px;
    pointer-events: none;
    a {
        display: block;
        padding: 6px 0;
        white-space: nowrap;

        &:hover,
        &.active {
            border-bottom: none;
            margin-bottom: 0;
            span {
                border-bottom: 1px solid var(--clr_portal_100);
            }
        }
    }
    li {
        font-size: 16px;
        &:last-child {
            border-bottom: none;
        }
        &[data-id='cuines'],
        &[data-id='eva'],
        &[data-id='aran'] {
            a {
                border-bottom: 1px solid rgba(102, 102, 102, 1);
                padding-bottom: 10px;
                margin-bottom: 2px;
            }
        }
    }
    img {
        margin: -4px 0 -3px;
    }
}
.elementsDreta {
    margin-left: auto;
    display: flex;
    position: relative;
    align-items: center;
}
.burguer,
.botoCerca,
.botoLogin {
    opacity: 1;
    &:hover,
    &:focus {
        opacity: 0.8;
    }
}
.iconaCerca {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.botoUsuari {
    width: 28px;
    height: 28px;
    background: #fff;
    color: #000;
    border-radius: 14px;
    display: inline-block;
    text-align: center;
    position: relative;

    span {
        position: relative;
        font-size: var(--headline_xs);
        line-height: var(--headline_xs);
        top: 0px;
    }
}

.dropdown.menuUsuari {
    left: auto;
}

.menuUsuari {
    right: -29px;
    // left: auto;
    top: 40px;
    &.open {
        opacity: 1;
        max-height: 600px;
        overflow: visible;
        pointer-events: all;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -8px;
        right: 35px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent rgba(0, 0, 0, 0.95) transparent;
    }
    .nom {
        font-size: var(--headline_xs);
        padding: 14px 0 16px;
        font-weight: 800;
        a {
            padding: 0;
        }
    }
    ul {
        border-bottom: 1px solid global.$color80;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        li {
            .opcio_usuari {
                display: inline-block;
                border-bottom: 1px solid transparent;
                padding-bottom: 1px;
                margin-bottom: 15px;
                margin-right: 10px;
                &:hover {
                    border-bottom: 1px solid currentColor;
                    margin-bottom: 15px;
                }
            }
            .contOpcioIcona {
                display: flex;
                .ico_sortir {
                    margin-top: 2px;
                }
            }
        }
    }
    a,
    .botoTanca {
        display: inline-block;
        border-bottom: 1px solid transparent;
        padding-bottom: 1px;
        margin-bottom: 6px;
        margin-right: 10px;
        &:hover {
            border-bottom: 1px solid currentColor;
            margin-bottom: 6px;
        }
    }
    .tanca {
        position: absolute;
        top: 18px;
        right: 18px;
        margin-right: 0;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}

.titol {
    font-size: var(--headline_s);
    font-weight: 300;
    display: none;
    padding-left: 10px;
}

//MENU HAMBURGUER
.mask.openBurguer {
    opacity: 1;
    pointer-events: all;
    .sideMenu {
        left: 0;
    }
}

.mask.openBurguer .logo {
    width: 111px;
    height: 32px;
}

.ico_boto {
    width: 24px;
    height: 24px;
    margin: 16px 16px 16px 0;
}

.ambIcona {
    display: inline-flex;
    column-gap: 5px;
    align-items: center;
    line-height: 1;
}

.ico_menuDesplega {
    width: 22px;
    height: 22px;
    transition: all 0.2s;
}
.sideMenu {
    width: 370px;
    background: var(--clr_portal_100);
    top: 0;
    left: -438px;
    bottom: 0;
    position: fixed;
    transition: all 0.5s;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        height: 64px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
        transition: all 0.2s;
    }
    &.bottom:after {
        opacity: 0;
    }
}
.mask {
    content: '';
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    z-index: 60;
}
.header {
    padding: 21px 0 0 54px;
    display: flex;
    align-items: center;
    height: 48px;
}
.overflowWrapper {
    height: calc(100vh - 68px);
    margin-top: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
.tanca {
    width: 30px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    cursor: pointer;
}
.ico_usuari {
    width: 24px;
    height: 24px;
}
.ico_tanca {
    width: 20px;
    height: 20px;
}
.menuSeccions {
    padding: 20px 30px 30px 100px;
    li {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 13px;
        a:hover {
            border-bottom: 1px solid;
        }
    }
}
.opcioAmbIcona {
    height: 21px;
}
.sideSubmenu {
    margin-top: 13px;
    padding-left: 32px;
    overflow: hidden;
    max-height: 0;
    transition: all 0.2s;
    li {
        font-weight: 300;
        font-size: 18px;
        &[data-id='cuines'],
        &[data-id='eva'],
        &[data-id='aran'] {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            padding-bottom: 13px;
        }
    }
    .open & {
        max-height: 542px;
    }
}
.dropButton {
    cursor: pointer;
    display: flex;
    column-gap: 5px;
    align-items: center;
    .open & {
        .ico_menuDesplega {
            transform: rotate(180deg);
        }
    }
    &:hover {
        border-bottom: 1px solid;
        margin-bottom: -1px;
    }
}

.footer {
    width: 100%;
    padding-bottom: 24px;
    background: global.$color100;
}
.footerCanals {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 40px 0;
    padding-bottom: 16px;
    justify-content: space-between;
    border-bottom: 1px solid global.$color70;
    align-items: center;
    img {
        height: 28px;
        filter: brightness(0) invert(1);
        pointer-events: none;
    }
    a {
        opacity: 0.85;
        padding: 0 12px;
        &:hover {
            opacity: 1;

            img {
                filter: none;
            }
        }
    }
}

.footerLogo3cat {
    flex-basis: 100%;
    margin-bottom: 20px;
    text-align: center;
    img {
        width: 111px;
        height: 32px;
        filter: brightness(0) invert(1);
        pointer-events: none;
    }
}
.footerSeccions {
    margin: 0 40px;
    padding: 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px 24px;
    align-items: center;
    font-weight: 400;
    border-bottom: 1px solid var(--clr_trans_70);
    &:last-child {
        border-bottom: none;
    }
    .textLight {
        font-size: 14px;
    }
    li {
        text-transform: lowercase;
        font-size: 18px;
        text-align: center;
    }
    a {
        text-transform: uppercase;
        color: var(--clr_trans_20);
        &:hover {
            color: var(--clr_trans_0);
        }
    }
}

.ico_cerca {
    margin-bottom: -2px;
}

.cercador_link {
    display: flex;
    column-gap: 3px;
    align-items: center;
}

//BARRA HOME
.barra {
    width: 100%;
    background-color: var(--clr_trans_100);
    height: 36px;
    padding: 0 36px;
    position: relative;
    z-index: 10;
}
.menuBarra {
    display: flex;
    justify-content: right;
    max-width: 2144px;
    margin: 0 auto;
    li {
        padding: 8px;
        margin-right: 8px;
        text-transform: uppercase;
        font-size: var(--body_xs);
        font-weight: 700;
        position: relative;
        height: 36px;
        display: flex;
        align-items: center;
        &.textLight {
            font-weight: 400;
        }
        &.separador:after {
            content: '';
            display: block;
            width: 1px;
            height: 24px;
            background-color: var(--clr_trans_70);
            position: absolute;
            top: 8px;
            right: -6px;
        }
        &:last-child {
            padding-right: 0;
            margin-right: 0;
        }
        a {
            display: flex;
            align-items: center;
            color: var(--clr_trans_10);
            &:hover {
                color: var(--clr_trans_0);
                .logo {
                    filter: none;
                }
            }
        }
    }
}

//MENU UNTIL LARGE
@media #{global.$VP_LARGE_NEGATION} {
    .logo {
        margin-right: 32px;
    }
}

//MENU MÒBIL
@media #{global.$VP_SMALL_MOBILE} {
    .dropdown {
        min-width: fit-content;
        padding: 30px 15px;
    }
}
@media (max-width: 438px) {
    .sideMenu {
        width: 100%;
    }
    .footerSeccions {
        padding: 0 24px 20px;
        margin: 0 24px;
        &:last-child {
            margin-top: 20px;
        }
    }
    .footerCanals {
        margin: 20px 24px;
    }
    .elementsDreta {
        position: static;
    }
    .dropdown.menuUsuari {
        &:before {
            content: none;
        }
        position: fixed;
        top: 0;
        right: calc(-100% - 60px);
        bottom: 0;
        left: auto;
        width: 100%;
        max-height: none;
        transition: all 0.5s;
        opacity: 1;
        padding: 50px 60px;
        &.open {
            right: 0;
        }
    }
}
@media #{global.$VP_ALL_MOBILE} {
    .mobilReduit {
        .titol {
            display: block;
        }
    }
    .sideSubmenu li {
        font-size: 18px;
        max-width: 92%;
    }
    .menuSeccions {
        padding: 20px 30px 30px 56px;
    }
    .noGapMobile {
        margin-bottom: 0;
        height: auto;
    }
    .reduit {
        .menuItems {
            display: none;
        }
    }
    .dropdown {
        font-size: var(--headline_xs);
    }
    .logo {
        width: 84px;
        height: 24px;
        margin-right: 2px;
        margin-top: -2px;
    }

    .menuUsuari {
        .tanca {
            top: 23px;
            right: 28px;
        }
    }
    .menuWrapperGradient {
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
    }
}
@media #{global.$VP_MOBILE_AND_TP} {
    .ico_boto {
        margin-right: 10px;
    }
    .footerLogo3cat {
        img {
            width: 98px;
            height: 28px;
        }
    }

    .menuWrapper {
        height: 56px;
        padding: 0 16px;
        margin-bottom: 0;
        pointer-events: all;
    }

    .menuWrapper:not(.menuWrapperGradient) {
        background: var(--clr_trans_100);
    }

    .menu {
        z-index: 10;
        background-color: transparent;
        pointer-events: none;
        height: auto;
        &:before {
            content: none;
        }
    }
    .header {
        padding: 10px 0 0 13px;
    }
    .submenu {
        position: absolute;
        left: -1px;
        z-index: 9;
        top: 142px;
        .menuItems {
            position: absolute;
            top: 56px;
            left: 0;
            padding: 0 18px;
            width: 100%;
            z-index: 6;
            overflow: hidden;
            pointer-events: none;
            height: calc(100vh - 56px);
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 63.33%, rgba(0, 0, 0, 0) 100%);
            :global {
                .swiper-wrapper {
                    pointer-events: all;
                    height: 40px;
                }
            }
            a:hover,
            button:hover {
                border-bottom: none;
                margin-bottom: 0;
            }
        }
    }
    .barra {
        display: none;
    }
    .menuItem {
        height: 40px;
    }
    .noMobile {
        display: none;
    }
    .dropdown {
        top: 38px;
        left: auto;
        right: 0;
    }
    .menuUsuari {
        &:before {
            right: 7px;
        }
    }
    .link,
    .botoDropdown {
        padding-top: 15px;
        margin-top: -15px;
    }
    .botoDropdown {
        height: 33px;
    }
    .botoUsuari {
        font-size: var(--headline_s);
        line-height: 28px;
    }
    .titol {
        font-size: var(--body_l);
        padding-right: 5px;
    }
}
//MENU REGULAR
@media #{global.$VP_REGULAR_AND_TL} {
    .submenu .menuItems {
        left: 208px;
    }
}
@media #{global.$VP_EXTRA_LARGE} {
    .submenu {
        margin-top: -165px;
        margin-bottom: 149px;
    }
    .barra {
        padding: 0 56px;
    }
}

//MENU TP
@media #{global.$VP_TP} {
    .botoUsuari {
        font-size: var(--headline_xs);
    }
    .menuWrapper {
        padding: 0 32px;
    }
    .menuWrapperGradient {
        background: var(--clr_trans_100);
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .menuWrapper {
        padding: 0 34px;
    }
}

@media #{global.$VP_EXTRA_LARGE} {
    .menuWrapper {
        padding: 0 56px;
    }
}

//SWIPER DESKTOP
@media #{global.$VP_BEYOND_TP} {
    .menuItems {
        &.centre {
            mask-image: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(255, 255, 255, 1) 80px,
                rgba(255, 255, 255, 1) calc(100% - 80px),
                rgba(0, 0, 0, 0) 100%
            );
        }
        &.dreta {
            mask-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) calc(100% - 80px),
                rgba(0, 0, 0, 0) 100%
            );
        }
        &.esquerra {
            mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 80px, rgba(255, 255, 255, 1) 100%);
        }
    }
    .submenu {
        :global {
            .swiper {
                background-image: none;
            }
        }
    }
}

//TEMES
@mixin textNegre {
    .ico_boto,
    .logo,
    .iconaMenu,
    .iconaDropdown,
    .iconaCerca,
    .menuItem > .link,
    .botoDropdown {
        filter: brightness(0);
    }
    .titol {
        color: #000;
    }
}

.T-noticies {
    &.menu {
        background: rgba(239, 125, 0, 0.7);
    }
    .menuWrapper {
        background: linear-gradient(89.95deg, #ff6600 0.04%, rgba(255, 102, 0, 0) 102.29%);
    }
    @include textNegre;
}
.T-esports {
    &.menu {
        background: rgba(255, 204, 0, 0.7);
    }
    .menuWrapper {
        background: linear-gradient(89.95deg, #ffcc00 0.04%, rgba(255, 204, 0, 0) 102.29%);
    }
    @include textNegre;
}
.T-cultura {
    &.menu {
        background: rgba(39, 179, 232, 0.7);
    }
    .menuWrapper {
        background: linear-gradient(89.95deg, #27b3e8 0.04%, rgba(39, 179, 232, 0) 102.29%);
    }
    @include textNegre;
}
.T-cuines {
    &.menu {
        background: rgba(200, 66, 145, 0.7);
    }
    .menuWrapper {
        background: linear-gradient(89.95deg, #c84291 0.04%, rgba(200, 66, 145, 0) 102.29%);
    }
    @include textNegre;
}

// min2144px

@media #{global.$VP_MASTODONTIC} {
    .menuWrapper {
        padding: 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header {
        padding-left: 30px;
    }
    .menuSeccions {
        padding-left: 70px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .header {
        padding-left: 30px;
    }
    .menuSeccions {
        padding-left: 70px;
    }
}

// AJUSTAR LA POSICIO DEL DROPDOWN EN DESKTOP
@media (min-width: 1080px) {
    .dropdown {
        left: -29px;
    }
}
